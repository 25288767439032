import React from "react";
import Modal from "react-modal";

// Styles pour la modale (vous pouvez les personnaliser)
const customStyles = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "25px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    color: "#0f172a",
    width: "80%",
    fontSize: "16px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

Modal.setAppElement("#root");

function ModalAlternance({ isOpen, onRequestClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Modal d'alternance"
    >
      <h2>
        Je suis à la <span>recherche</span> d'une <span>alternance</span> en
        Master pour <span>2025</span> !
      </h2>
      <p>
        Je suis développeur front-end et chef de projet digital (spécialité
        UX/UI) Bachelor 3. Je prépare un Master pour 2025 et recherche une
        alternance.
      </p>
      <p>n'hésitez pas à me contacter !</p>
      <button onClick={onRequestClose}>
        Continuer d'explorer le portfolio
      </button>
    </Modal>
  );
}

export default ModalAlternance;
